//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LandingPagePopUp from "@/views/LandingPage/LandingPagePopUp.vue";
import geneticAnalystIcon from "@/assets/genetic-analyst-icon.png";
import healthProfessionalIcon from "@/assets/health-professional-icon.png";
export default {
  name: "SelectRole",
  components: {
    LandingPagePopUp
  },
  data: () => ({
    geneticAnalystIcon,
    healthProfessionalIcon,
    roles: [{
      title: "Genetic Analyst",
      route: "genetic-analyst",
      icon: geneticAnalystIcon
    }, {
      title: "Health Professional",
      route: "health-professional",
      icon: healthProfessionalIcon
    }]
  }),
  methods: {
    previous() {
      this.$router.push({
        name: "landing-page"
      });
    }
  }
};